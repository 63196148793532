@import url(../font/circular-std-master/css/circular-std.css);
@import url(~react-datepicker/dist/react-datepicker.css);
@import url(~react-chat-elements/dist/main.css);
@import url(~perfect-scrollbar/css/perfect-scrollbar.css);
.title {
  font-family: "CircularStd";
  line-height: 30px;
  font-size: 1.6875rem !important;
  font-weight: 400 !important;
  border-radius: 3px;
  text-transform: none;
  color: black;
  justify-self: center; }
  .title:hover, .title:focus {
    background: transparent; }

.top-right-float {
  position: absolute;
  right: 0;
  top: 0; }

.content-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: visible !important;
  overflow-x: visible !important;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px !important; }

.horz-container {
  display: flex;
  flex-direction: row;
  flex-flow: wrap; }
  .horz-container.space-between {
    justify-content: space-between; }
  .horz-container.content-end {
    justify-content: flex-end; }
  .horz-container.align-center {
    align-items: center; }

.full-width {
  width: 100% !important; }

.agree-terms-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; }
  .agree-terms-container label, .agree-terms-container a {
    font-size: 1rem; }
  .agree-terms-container .plain-text {
    color: black;
    font-weight: 400;
    margin: 0 4px;
    line-break: none; }
  .agree-terms-container .link-text {
    color: #304ffe;
    font-weight: 400; }
  .agree-terms-container .patient-clr {
    color: #304ffe; }
    .agree-terms-container .patient-clr.checked {
      color: #304ffe; }
  .agree-terms-container .doctor-clr {
    color: #37c977; }
    .agree-terms-container .doctor-clr.checked {
      color: #37c977; }
  @media only screen and (max-width: 768px) {
    .agree-terms-container label, .agree-terms-container a {
      font-size: .75rem; } }

.alert-dialog-container #confirmation-dialog-title h2 {
  font-size: 1.5rem;
  font-family: 'CircularStd'; }

.alert-dialog-container .message {
  font-size: 1.25rem;
  font-family: 'CircularStd';
  font-weight: 400; }

.alert-dialog-container .btn {
  font-size: 1.5rem;
  font-family: 'CircularStd'; }

.alert-dialog-container textarea {
  border-color: #dbdbdb;
  border-radius: 4px;
  border-width: 2px;
  width: 100%;
  height: 200px; }

.alert-dialog-container .answer-question-content textarea {
  min-width: 396px;
  max-width: 396px;
  max-height: 170px;
  min-height: 170px;
  height: 170px !important; }

.alert-dialog-container .save-btn {
  margin-top: 1rem !important; }

.alert-dialog-container .title {
  margin-bottom: 1rem !important; }

.alert-dialog-container .save-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%; }
  .alert-dialog-container .save-button-wrapper .full-width {
    width: 100% !important; }

.black-green-button {
  color: black !important; }
  .black-green-button:hover {
    color: #37c977 !important; }

.signature-root-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  .signature-root-container .signature-main-contents {
    background-color: white;
    border-radius: 6px;
    overflow: hidden;
    padding: 20px; }
  .signature-root-container .sigCanvas {
    border: 1px solid black; }

.tooltip-container {
  display: flex;
  flex-direction: column; }
  .tooltip-container .name {
    color: black;
    font-size: 12px;
    font-weight: 600; }
  .tooltip-container .practice {
    color: #72ce5f;
    font-size: 12px;
    font-weight: 500;
    margin: 4px 0; }
  .tooltip-container .known-view {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center; }
    .tooltip-container .known-view img {
      width: 20px; }
    .tooltip-container .known-view label {
      font-size: 8pt;
      margin: 0;
      margin-left: 4px; }

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1; }
  .header-container .horz-container {
    margin-left: 0px; }
  .header-container .nav-title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center; }

.header-title {
  color: black;
  font-size: 1.6875rem !important;
  font-family: CircularStd;
  font-weight: 400 !important;
  line-height: 30px;
  justify-self: center;
  border-radius: 3px;
  text-transform: none; }

@media only screen and (max-width: 768px) {
  .header-container {
    justify-content: space-between; }
    .header-container .horz-container {
      justify-content: space-between;
      margin-left: 0px; } }

.info-bar-container .info-label {
  font-size: 1rem;
  font-weight: 100; }

.info-bar-container .info-ico {
  width: 20px;
  margin-right: 4px; }

.info-bar-container .like-ico {
  fill: #FF1744; }

.info-bar-container .agree-ico {
  fill: #37c977; }

.info-bar-container .userknow-ico {
  fill: #1b66e9; }

.info-bar-container .info-value {
  font-size: 1.2rem;
  margin: 0;
  color: black;
  font-weight: 100; }

.nav-feed-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  margin-right: 20px;
  font-size: 1.5rem;
  font-weight: 100 !important; }
  .nav-feed-container .search {
    margin: 0;
    padding: 4px;
    flex: 1;
    max-width: 320px !important;
    border: none; }
    .nav-feed-container .search:focus {
      outline-color: #37c977; }
  .nav-feed-container .search-button {
    min-width: unset;
    min-height: unset;
    width: 36px;
    height: 36px;
    padding: 0;
    border-radius: 50%; }
  .nav-feed-container .dropdown-container i.fa {
    padding-right: 0.6rem !important; }
  .nav-feed-container .dropdown-container label.item-input {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }

@media only screen and (max-width: 768px) {
  .nav-feed-container {
    padding-left: 0;
    margin-right: 20px; }
    .nav-feed-container .search {
      width: 100%; } }

.patient-profile-container > div {
  box-shadow: none; }

.patient-profile-container .MuiCardActions-spacing button {
  margin: 16px 16px 100px 16px !important; }

.service-info-item-container {
  width: 100%;
  margin: 0;
  padding: 4px 0; }
  .service-info-item-container .info-desp {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .service-info-item-container .info-desp .info-ico {
      fill: #CCC;
      margin-right: 14px; }
    .service-info-item-container .info-desp .info-label {
      font-size: 1.2rem;
      font-weight: 100;
      margin: 0; }
  .service-info-item-container .info-value {
    font-size: 1.2rem;
    margin: 0;
    color: black;
    font-weight: 100; }

@media only screen and (max-width: 768px) {
  .service-info-item-container {
    padding: 4px 0; } }

.control-container {
  margin: 8px 0 !important;
  width: 100%; }
  .control-container > div {
    padding-top: 0px; }
  .control-container .caption {
    left: 8px;
    top: 2px;
    font-family: 'CircularStd';
    font-weight: 400;
    font-size: 12pt;
    color: #888888; }
  .control-container .focused {
    color: black; }
  .control-container .value-input {
    line-height: normal;
    width: 100%;
    padding: 5px 8px;
    font-family: 'CircularStd';
    font-size: 12pt;
    border-radius: 8px; }
    .control-container .value-input div {
      min-height: unset;
      margin: 0;
      padding: 5px 0; }
    .control-container .value-input.read-only {
      border: none; }
      .control-container .value-input.read-only svg {
        display: none; }
  .control-container .underline:before, .control-container .underline:after, .control-container .underline:hover:before, .control-container .underline:focus:before {
    background-color: transparent !important; }
  .control-container .MuiSelect-icon {
    top: unset;
    right: 4px; }

.toggle {
  display: flex;
  justify-content: center;
  align-items: center; }

.toggle-enter {
  opacity: 0.0; }

.toggle-enter-active {
  opacity: 1;
  transition: all 600ms ease-out; }

.toggle-exit {
  opacity: 1; }

.toggle-exit-active {
  opacity: 0.01;
  transition: all 600ms ease-out; }

.twitter-login-dialog label {
  margin-right: 10px; }

.twitter-login-dialog input {
  max-width: 80%; }

.twitter-login-dialog #confirmation-dialog-title h2 {
  font-size: 12pt;
  font-family: 'CircularStd'; }

.twitter-login-dialog .message {
  font-size: 12pt;
  font-family: 'CircularStd';
  font-weight: 400; }

.twitter-login-dialog .btn {
  font-size: 12pt;
  font-family: 'CircularStd'; }

.filter-main-cont .value-input {
  font-weight: 100 !important; }

.filter-main-cont .title-wrapper {
  margin-bottom: 1rem !important; }

.filter-main-cont .use-date-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px; }
  .filter-main-cont .use-date-container .sex-label {
    font-weight: 100 !important; }
  .filter-main-cont .use-date-container .MuiSwitch-root {
    float: right;
    margin-right: 0px; }

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  /**************** Date picker css ***************/
  /*************************************************/ }
  .user-info .pick-file-button {
    background-color: #72ce5f;
    text-transform: none;
    color: white;
    font-size: 0.8em; }
  .user-info .item-input-desc.first {
    margin-top: 0px; }
  .user-info .item-input-desc {
    padding-left: 3px;
    padding-right: 14px;
    margin-top: 10px;
    font-size: 12pt;
    font-weight: 100;
    color: #888; }
  .user-info .item-input {
    height: fit-content !important; }
  .user-info .sub-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 24px 0; }
    .user-info .sub-title label {
      font-size: 1.25rem;
      font-weight: 600; }
  .user-info .sub-body {
    padding: 0px 0px 10px 0px; }
  .user-info .mt-20p {
    margin-top: 20px; }
  .user-info .mt-50p {
    margin-top: 50px; }
  .user-info .sub-desc {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.3);
    padding: 0px 3px; }
  .user-info nav {
    padding: 0 !important; }
  .user-info .ico-content {
    padding-right: 28px;
    font-size: 1.25rem;
    color: #888888; }
  .user-info .ico-wrapper {
    pointer-events: none;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center; }
  .user-info .file-picker-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
  .user-info .attached {
    margin-right: 12px;
    color: #72ce5f;
    font-size: 10pt; }
  .user-info .picker-button {
    padding: 0;
    max-width: none !important;
    width: 100%;
    text-transform: none; }
  .user-info .placeholder {
    color: #888888 !important; }
  .user-info .react-datepicker-wrapper {
    display: block; }
  .user-info .react-datepicker__input-container {
    display: block; }
  .user-info .react-datepicker__navigation {
    top: 16px; }
  .user-info .react-datepicker__current-month,
  .user-info .react-datepicker__year-dropdown-container,
  .user-info .react-datepicker__day-name,
  .user-info .react-datepicker__day {
    font-size: 12pt;
    margin: 4px 8px; }

.item-input {
  text-align: left;
  width: 100%;
  font-size: 1.25rem !important;
  font-weight: 100 !important;
  color: black !important;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  background-color: transparent;
  height: 56px;
  padding: 8px 14px;
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .item-input:placeholder {
    color: #888888; }
  .item-input.menu-item {
    border: none !important; }
  .item-input:disabled {
    border: none !important; }

@media only screen and (max-width: 768px) {
  .user-info .dropdown-container {
    min-height: 48px; }
  .user-info .ico-content {
    font-size: 12pt;
    padding-right: 16px; }
  .user-info .sub-title label {
    font-size: 12pt; }
  .user-info .item-container {
    padding: 4px 0 !important; }
  .item-input {
    font-size: 1rem;
    padding: .75rem;
    line-height: 1rem; } }

.auth-form-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
  color: #888888;
  /* The container */ }
  .auth-form-container input[type=text], .auth-form-container input[type=password] {
    font-family: 'CircularStd';
    font-size: 1.5rem;
    font-weight: 400;
    margin: 15px 16px 0px 16px;
    padding: 12px;
    width: 100%;
    text-align: center;
    border: none;
    border-bottom: 2px solid #cccccc; }
    .auth-form-container input[type=text]::placeholder, .auth-form-container input[type=password]::placeholder {
      color: rgba(136, 136, 136, 0.7); }
    .auth-form-container input[type=text]:focus, .auth-form-container input[type=password]:focus {
      border-color: #37c977;
      outline-color: #37c977; }
    .auth-form-container input[type=text]:-webkit-autofill, .auth-form-container input[type=password]:-webkit-autofill {
      background-color: white !important;
      -webkit-box-shadow: 0 0 0px 1000px white inset; }
  .auth-form-container .forgot-password {
    margin: 16px 0;
    font-size: 14pt;
    font-weight: 400;
    color: rgba(136, 136, 136, 0.7);
    cursor: pointer; }
    .auth-form-container .forgot-password:hover {
      color: #888888; }
  .auth-form-container .check-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    cursor: pointer;
    font-size: 14pt;
    font-weight: 400;
    color: rgba(136, 136, 136, 0.7); }
    .auth-form-container .check-container:hover {
      color: #888888; }
  .auth-form-container .check-input {
    justify-content: flex-start;
    margin-right: .5rem;
    width: 32px; }
  .auth-form-container .btn {
    width: 100%;
    margin: 48px 0 0;
    border-radius: 10px;
    padding: 10px 8px !important;
    font-weight: 400;
    font-size: 1.5rem;
    text-transform: none;
    outline: unset; }
    .auth-form-container .btn:active {
      outline: unset; }
    .auth-form-container .btn:hover {
      background-color: rgba(55, 201, 119, 0.7); }
  .auth-form-container .classic-login-button {
    background-color: #37c977;
    color: white; }
  .auth-form-container .classic-signup-button {
    background-color: #37c977;
    color: white; }
  .auth-form-container .sign-up-with {
    color: rgba(136, 136, 136, 0.7);
    margin: 25px 0px;
    font-size: 1.25rem;
    font-weight: 100; }
  .auth-form-container .social-view {
    display: flex;
    flex-direction: row; }
    .auth-form-container .social-view .social-login-button {
      border-width: 0;
      border-radius: 50%;
      margin: 0 10px;
      padding: 0;
      width: 35px;
      background-color: transparent; }
      .auth-form-container .social-view .social-login-button img {
        width: 100%; }

@media only screen and (max-width: 768px) {
  .auth-form-container {
    width: 80%;
    padding-top: 20px; }
    .auth-form-container input[type=text], .auth-form-container input[type=password] {
      font-size: 12pt;
      padding: 6px; }
    .auth-form-container .forgot-password {
      font-size: 11pt; }
    .auth-form-container .check-container {
      font-size: 11pt;
      margin: 0; }
    .auth-form-container .btn {
      font-size: 1rem;
      height: 48px; }
    .auth-form-container .sign-up-with {
      font-size: 11pt; } }

.auth-select-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 550px;
  position: relative;
  background-image: url("../img/authentication/back.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  overflow: hidden;
  z-index: 0; }
  .auth-select-container::after {
    content: "";
    background-color: black;
    opacity: 0.55;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 0; }
  .auth-select-container .back-btn {
    z-index: 1;
    font-size: 24pt;
    color: white;
    position: absolute;
    left: 24px;
    top: 24px; }
  .auth-select-container .content {
    z-index: 1;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .auth-select-container .content .top-space {
      flex: 1; }
    .auth-select-container .content .logo-space {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .auth-select-container .content .logo-space .logo {
        width: 80px;
        height: auto; }
      .auth-select-container .content .logo-space .app-name {
        margin-top: 10px;
        font-weight: 600;
        font-size: 2rem;
        color: white; }
      .auth-select-container .content .logo-space .desp {
        margin-top: 20px;
        font-weight: 400;
        font-size: 1rem;
        color: white;
        opacity: 0.8;
        text-align: center; }
    .auth-select-container .content .buttons-space {
      flex: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .auth-select-container .content .buttons-space .btn {
        width: 380px;
        height: 64px;
        border-radius: 10px;
        font-family: 'CircularStd';
        font-size: 1.25rem;
        font-weight: 600;
        color: white;
        text-transform: none;
        outline: unset; }
        .auth-select-container .content .buttons-space .btn:active {
          outline: unset; }
      .auth-select-container .content .buttons-space .sign-in {
        border: 2px solid rgba(255, 255, 255, 0.3);
        font-weight: 400; }
      .auth-select-container .content .buttons-space .sign-up {
        margin-top: 16px;
        background-color: #37c977;
        font-weight: 400; }

@media only screen and (max-width: 800px) {
  .auth-select-container {
    min-height: unset; }
    .auth-select-container .content .logo {
      width: 90px;
      height: 90px;
      margin-bottom: 12px; }
    .auth-select-container .content .app-name {
      font-size: 24pt;
      margin-bottom: 28px; }
    .auth-select-container .content .desp {
      font-size: 11pt;
      margin-bottom: 40px; }
    .auth-select-container .content .btn {
      width: 190px;
      height: 40px;
      font-size: 11pt; } }

.authentication-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 812px; }
  .authentication-container .logo-view {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: url("../img/authentication/back.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    overflow: hidden;
    z-index: -1; }
    .authentication-container .logo-view::after {
      content: "";
      background-color: black;
      opacity: 0.55;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0; }
    .authentication-container .logo-view .logo {
      z-index: 1; }
      .authentication-container .logo-view .logo img {
        width: 80px; }
    .authentication-container .logo-view .logo-title {
      margin-top: 10px;
      z-index: 1; }
      .authentication-container .logo-view .logo-title label {
        font-size: 2rem; }
        .authentication-container .logo-view .logo-title label.e {
          color: white; }
        .authentication-container .logo-view .logo-title label.docine {
          color: white; }
    .authentication-container .logo-view .sub-title {
      margin-top: 20px;
      z-index: 1;
      font-size: 1rem;
      font-weight: 400;
      text-align: center;
      padding: 0 8px;
      color: rgba(255, 255, 255, 0.8); }
      .authentication-container .logo-view .sub-title label {
        font-weight: 400 !important; }
  .authentication-container .card {
    width: 50%;
    height: 100%;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .authentication-container .back-link {
    margin-top: 15px;
    color: rgba(136, 136, 136, 0.7);
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.25rem; }
  .authentication-container .back-link:hover {
    text-decoration: underline;
    color: black; }

@media only screen and (max-width: 812px) {
  .authentication-container {
    flex-direction: column; }
    .authentication-container .logo-view {
      width: 100%;
      height: 50%; }
      .authentication-container .logo-view::after {
        width: 100%;
        height: 100%;
        z-index: 0; }
      .authentication-container .logo-view .logo img {
        width: 80px; }
      .authentication-container .logo-view .logo-title {
        margin-top: 15px; }
      .authentication-container .logo-view .sub-title {
        font-size: 1rem; }
    .authentication-container .card {
      background-color: white;
      z-index: 2;
      width: 100%;
      justify-content: flex-start; } }

.book-instant-message-main-cont .title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -44px !important;
  margin-bottom: 1rem !important;
  overflow-x: visible; }
  .book-instant-message-main-cont .title-wrapper button {
    padding: 0px 10px 0px 10px; }

.book-instant-message-main-cont .title {
  margin-bottom: 0 !important; }

.book-instant-message-main-cont .book-instant-message-container .sub-container {
  width: 100%; }
  .book-instant-message-main-cont .book-instant-message-container .sub-container .caption {
    font-size: 12pt;
    font-weight: 400; }
  .book-instant-message-main-cont .book-instant-message-container .sub-container .note {
    font-size: 12pt;
    font-weight: 400;
    width: 100%;
    height: 200px;
    margin: 0 0 16px; }
  .book-instant-message-main-cont .book-instant-message-container .sub-container table {
    margin: 24px 0;
    width: 100%; }
  .book-instant-message-main-cont .book-instant-message-container .sub-container th {
    font-size: 12pt;
    font-weight: 500;
    padding: 6px 0px;
    cursor: default; }
  .book-instant-message-main-cont .book-instant-message-container .sub-container td {
    font-size: 12pt;
    font-weight: 400;
    padding: 6px 0px;
    cursor: pointer; }
  .book-instant-message-main-cont .book-instant-message-container .sub-container tr {
    border-bottom: 1px solid #dbdbdb; }
    .book-instant-message-main-cont .book-instant-message-container .sub-container tr:hover {
      background-color: rgba(27, 102, 233, 0.1); }

.booking-cancel-start-container .title {
  margin-bottom: 1.5rem !important; }

.booking-cancel-start-container .content {
  font-size: 12pt;
  font-weight: 400;
  border: 2px solid #ebeae5;
  border-radius: 2px; }

.booking-cancel-start-container .caption {
  margin-top: 8px;
  font-size: 12pt;
  font-weight: 500; }

.booking-cancel-start-container .reason {
  height: 200px; }

.booking-cancel-start-container .avatar-container {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

.booking-cancel-start-container .avatar {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50px;
  overflow: hidden; }

.booking-cancel-start-container .rating-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.booking-cancel-start-container .star-ratings {
  width: max-content; }

.booking-cancel-start-container .btn {
  margin-top: 8px;
  width: 100%;
  font-size: 12pt;
  font-weight: 400;
  color: #37c977;
  background-color: rgba(55, 201, 119, 0.1); }
  .booking-cancel-start-container .btn:hover {
    color: white;
    background-color: #37c977; }

.bookings-container .title {
  font-size: 1.6875rem;
  font-weight: 600;
  color: black;
  margin-bottom: 20px !important; }

.bookings-container .item {
  padding: 8px 8px 8px 0px !important;
  border-bottom: 1px solid #ebeae5;
  margin-left: 0px !important;
  height: fit-content !important;
  margin-top: 0px !important; }
  .bookings-container .item img {
    width: 50px !important;
    height: 50px !important; }
  .bookings-container .item span.MuiTypography-root {
    font-size: 1rem !important;
    font-weight: 400 !important;
    color: black !important; }
  .bookings-container .item p.MuiTypography-root {
    font-size: 0.85rem !important;
    font-weight: 400 !important; }
  .bookings-container .item p.MuiTypography-root.red {
    color: #FF0000; }
  .bookings-container .item p.MuiTypography-root.green {
    color: #37c977; }

.bookings-container .arrow {
  color: #888888; }

.bookings-container .desp {
  font-family: 'CircularStd';
  font-size: 12pt;
  font-weight: 500; }

.bookings-container .status {
  font-family: 'CircularStd';
  font-size: 10pt;
  font-weight: 400; }

.bookings-container .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 8px; }

.audiovideo-chat-room-container + div .btn {
  margin-top: 8px !important;
  width: 100% !important;
  font-size: 12pt !important;
  font-weight: 400 !important;
  color: #37c977 !important;
  background-color: rgba(55, 201, 119, 0.1) !important; }
  .audiovideo-chat-room-container + div .btn:hover {
    color: white !important;
    background-color: #37c977 !important; }

.audiovideo-chat-room-container + div .btn-end {
  color: red !important;
  background-color: rgba(255, 0, 0, 0.1) !important; }
  .audiovideo-chat-room-container + div .btn-end:hover {
    color: white !important;
    background-color: red !important; }

.instant-chat-room-container .extra-description-container, .chat-room-container .extra-description-container, .audiovideo-chat-room-container .extra-description-container {
  padding: .5rem 1rem;
  font-size: 1rem;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .instant-chat-room-container .extra-description-container .time, .chat-room-container .extra-description-container .time, .audiovideo-chat-room-container .extra-description-container .time {
    font-size: 1rem;
    color: black; }
  .instant-chat-room-container .extra-description-container .message-contents, .chat-room-container .extra-description-container .message-contents, .audiovideo-chat-room-container .extra-description-container .message-contents {
    font: inherit;
    flex: 1; }
  .instant-chat-room-container .extra-description-container .end-chat-button, .chat-room-container .extra-description-container .end-chat-button, .audiovideo-chat-room-container .extra-description-container .end-chat-button {
    margin-left: 10px;
    color: red !important;
    background-color: rgba(255, 0, 0, 0.1) !important; }
    .instant-chat-room-container .extra-description-container .end-chat-button:hover, .chat-room-container .extra-description-container .end-chat-button:hover, .audiovideo-chat-room-container .extra-description-container .end-chat-button:hover {
      color: white !important;
      background-color: red !important; }

.instant-chat-room-container .chat-area, .chat-room-container .chat-area, .audiovideo-chat-room-container .chat-area {
  height: calc(100vh - 250px);
  border: 2px solid #ebeae5;
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px;
  overflow-y: auto; }
  .instant-chat-room-container .chat-area .rce-mbox, .chat-room-container .chat-area .rce-mbox, .audiovideo-chat-room-container .chat-area .rce-mbox {
    background-color: lightgrey;
    color: black; }
    .instant-chat-room-container .chat-area .rce-mbox .rce-mbox-body, .chat-room-container .chat-area .rce-mbox .rce-mbox-body, .audiovideo-chat-room-container .chat-area .rce-mbox .rce-mbox-body {
      padding-bottom: 8px; }
      .instant-chat-room-container .chat-area .rce-mbox .rce-mbox-body .rce-mbox-title, .chat-room-container .chat-area .rce-mbox .rce-mbox-body .rce-mbox-title, .audiovideo-chat-room-container .chat-area .rce-mbox .rce-mbox-body .rce-mbox-title {
        color: black; }
        .instant-chat-room-container .chat-area .rce-mbox .rce-mbox-body .rce-mbox-title .rce-avatar-container .rce-avatar, .chat-room-container .chat-area .rce-mbox .rce-mbox-body .rce-mbox-title .rce-avatar-container .rce-avatar, .audiovideo-chat-room-container .chat-area .rce-mbox .rce-mbox-body .rce-mbox-title .rce-avatar-container .rce-avatar {
          background-color: white;
          border-radius: 50%; }
        .instant-chat-room-container .chat-area .rce-mbox .rce-mbox-body .rce-mbox-title span, .chat-room-container .chat-area .rce-mbox .rce-mbox-body .rce-mbox-title span, .audiovideo-chat-room-container .chat-area .rce-mbox .rce-mbox-body .rce-mbox-title span {
          flex: 1; }
      .instant-chat-room-container .chat-area .rce-mbox .rce-mbox-body .rce-mbox-time, .chat-room-container .chat-area .rce-mbox .rce-mbox-body .rce-mbox-time, .audiovideo-chat-room-container .chat-area .rce-mbox .rce-mbox-body .rce-mbox-time {
        color: rgba(0, 0, 0, 0.45); }
    .instant-chat-room-container .chat-area .rce-mbox .rce-mbox-left-notch, .chat-room-container .chat-area .rce-mbox .rce-mbox-left-notch, .audiovideo-chat-room-container .chat-area .rce-mbox .rce-mbox-left-notch {
      fill: lightgrey; }
    .instant-chat-room-container .chat-area .rce-mbox.rce-mbox-right, .chat-room-container .chat-area .rce-mbox.rce-mbox-right, .audiovideo-chat-room-container .chat-area .rce-mbox.rce-mbox-right {
      background-color: #37c977;
      color: white; }
      .instant-chat-room-container .chat-area .rce-mbox.rce-mbox-right .rce-mbox-body .rce-mbox-title, .chat-room-container .chat-area .rce-mbox.rce-mbox-right .rce-mbox-body .rce-mbox-title, .audiovideo-chat-room-container .chat-area .rce-mbox.rce-mbox-right .rce-mbox-body .rce-mbox-title {
        color: white; }
      .instant-chat-room-container .chat-area .rce-mbox.rce-mbox-right .rce-mbox-body .rce-mbox-time, .chat-room-container .chat-area .rce-mbox.rce-mbox-right .rce-mbox-body .rce-mbox-time, .audiovideo-chat-room-container .chat-area .rce-mbox.rce-mbox-right .rce-mbox-body .rce-mbox-time {
        color: rgba(255, 255, 255, 0.45); }
      .instant-chat-room-container .chat-area .rce-mbox.rce-mbox-right .rce-mbox-right-notch, .chat-room-container .chat-area .rce-mbox.rce-mbox-right .rce-mbox-right-notch, .audiovideo-chat-room-container .chat-area .rce-mbox.rce-mbox-right .rce-mbox-right-notch {
        fill: #37c977; }

.instant-chat-room-container .tool-area, .chat-room-container .tool-area, .audiovideo-chat-room-container .tool-area {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: flex-end; }
  .instant-chat-room-container .tool-area .send-button, .chat-room-container .tool-area .send-button, .audiovideo-chat-room-container .tool-area .send-button {
    background-color: transparent;
    border: none; }
    .instant-chat-room-container .tool-area .send-button .svg-fill, .chat-room-container .tool-area .send-button .svg-fill, .audiovideo-chat-room-container .tool-area .send-button .svg-fill {
      fill: lightgrey; }
    .instant-chat-room-container .tool-area .send-button:hover .svg-fill, .chat-room-container .tool-area .send-button:hover .svg-fill, .audiovideo-chat-room-container .tool-area .send-button:hover .svg-fill {
      fill: grey; }

.instant-chat-room-container {
  width: 100% !important; }

.audiovideo-chat-room-container.audio {
  height: 200px !important; }

.audiovideo-chat-room-container {
  width: 100% !important;
  height: calc(100vh - 200px);
  position: relative; }
  .audiovideo-chat-room-container .oppo-avatar + div {
    display: none; }
  .audiovideo-chat-room-container .oppo-avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem; }
    .audiovideo-chat-room-container .oppo-avatar img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      object-fit: cover;
      overflow: hidden; }
    .audiovideo-chat-room-container .oppo-avatar .oppo-username {
      font-size: 1.25rem;
      color: black;
      margin-left: .5rem; }
  .audiovideo-chat-room-container .publisher-video {
    position: absolute;
    width: 30%;
    height: 30%;
    right: 10px;
    bottom: 10px;
    border: 1px solid #505050; }
  .audiovideo-chat-room-container .publisher-video.audio {
    visibility: hidden; }
  .audiovideo-chat-room-container .subscriber-video {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;
    border: 1px solid #505050; }
  .audiovideo-chat-room-container .subscriber-video.audio {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }

.operations-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem 0rem; }
  .operations-container .operation-item {
    margin-left: 10px; }
  .operations-container .operation-item.first {
    margin-left: 0px; }

.doctor-payment-setting-container {
  font-size: 12pt;
  font-weight: 400; }
  .doctor-payment-setting-container div nav div {
    margin: 0px !important; }
  .doctor-payment-setting-container .title-wrapper {
    margin-bottom: 1rem !important; }
  .doctor-payment-setting-container .placeholder {
    color: #888888 !important; }
  .doctor-payment-setting-container .dropdown-container {
    background-color: transparent; }
    .doctor-payment-setting-container .dropdown-container .item {
      padding: 0 !important; }
    .doctor-payment-setting-container .dropdown-container .ico-content {
      padding-right: 28px;
      font-size: 1.6875rem;
      color: #888888; }
  .doctor-payment-setting-container .item-container {
    width: 100%;
    padding: 4px; }
  .doctor-payment-setting-container .divider {
    width: 100%;
    background-color: #dbdbdb;
    height: 1px;
    margin: 12px 0; }
  .doctor-payment-setting-container input {
    margin: 4px 0; }
  .doctor-payment-setting-container .balance {
    font-size: 12pt;
    font-weight: 400; }

.favorites-container .title {
  font-size: 1.6875rem;
  font-weight: 600;
  color: black;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: flex-start;
  min-height: 41px !important; }

.favorites-container .item {
  padding: 8px 0;
  border-bottom: 1px solid #dbdbdb; }

.favorites-container .arrow {
  color: #888888; }

.favorites-container .name {
  font-family: 'CircularStd';
  font-size: 1.25rem;
  font-weight: 100; }

.favorites-container .practice {
  font-family: 'CircularStd';
  font-size: 1rem;
  font-weight: 100;
  color: #72ce5f; }

.favorites-container .favourite-avatar {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50%; }

.favorites-container .MuiListItemText-root, .favorites-container .e-menu-logo-wrapper {
  padding-left: 0px !important;
  margin-left: 16px !important; }

.feed-article-detail-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 25%; }
  .feed-article-detail-container .title {
    font-size: 14pt; }
  .feed-article-detail-container .when {
    font-size: 10pt;
    font-weight: 400; }
  .feed-article-detail-container .article-body {
    margin: 8px 0;
    font-size: 12pt;
    font-weight: 400;
    line-height: 20pt; }
  .feed-article-detail-container .tile-img {
    width: 50px;
    margin: 8px 0; }
  .feed-article-detail-container .article-link {
    width: 100%;
    height: 80vh; }

.feed-article-link {
  width: 100%;
  height: 90vh;
  border: none !important; }

@media only screen and (max-width: 768px) {
  .feed-article-detail-container {
    width: 100%;
    margin-left: unset; } }

.feed-detail-container {
  display: flex;
  flex-direction: column;
  width: 100%; }

.feed-float-container {
  position: absolute;
  right: 30px;
  bottom: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row; }
  .feed-float-container .items-view .item {
    width: 100% !important;
    margin: 4px 0 !important;
    padding: 8px !important;
    border-radius: 4px !important;
    background-color: white !important;
    border-width: 2px !important;
    border-style: solid !important; }
    .feed-float-container .items-view .item label {
      font-weight: 400;
      margin-left: 6px; }
    .feed-float-container .items-view .item .icon-talk {
      fill: #37c977; }
    .feed-float-container .items-view .item .icon-post {
      fill: #1b66e9; }
    .feed-float-container .items-view .item .icon-question {
      fill: #FF1744; }
    .feed-float-container .items-view .item.talk {
      border-color: rgba(55, 201, 119, 0.3);
      color: #37c977; }
    .feed-float-container .items-view .item.post {
      border-color: rgba(27, 102, 233, 0.3);
      color: #1b66e9; }
    .feed-float-container .items-view .item.question {
      border-color: rgba(255, 23, 68, 0.3);
      color: #FF1744; }
  .feed-float-container .more {
    min-width: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #37c977;
    color: white;
    font-size: 18px;
    text-transform: none;
    margin-left: 6px;
    margin-bottom: 6px; }

@media only screen and (max-width: 768px) {
  .feed-float-container .more {
    background-color: rgba(114, 206, 95, 0.18);
    color: #72ce5f; } }

.feed-question-detail-container {
  display: flex;
  flex-direction: column; }
  .feed-question-detail-container .question {
    font-size: 14pt; }
  .feed-question-detail-container .when {
    font-size: 10pt;
    font-weight: 400; }
  .feed-question-detail-container .question-body {
    margin: 8px 0;
    font-size: 12pt;
    font-weight: 400;
    line-height: 20pt; }
  .feed-question-detail-container .divider {
    height: 1px;
    width: 100%;
    background-color: #eeeeee; }
  .feed-question-detail-container .answer {
    font-size: 14pt;
    align-self: center; }
  .feed-question-detail-container .answer-body {
    font-size: 12pt;
    font-weight: 400;
    line-height: 20pt; }
  .feed-question-detail-container .tile-img {
    width: 50px;
    margin: 8px 0; }

@media only screen and (max-width: 768px) {
  .feed-question-detail-container {
    width: 100%;
    margin-left: unset; } }

.feeds-container .doctor-view {
  width: 100%; }

.feeds-container .sub-info.buttons {
  padding-top: 15px;
  padding-bottom: 15px; }

.feeds-container .feeds-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 10px 0px 0px;
  margin-bottom: 12px; }
  .feeds-container .feeds-item .title {
    font-size: 1.6875rem;
    font-weight: 600; }
  .feeds-container .feeds-item .profile-view {
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
    .feeds-container .feeds-item .profile-view .avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-right: 8px;
      cursor: pointer; }
    .feeds-container .feeds-item .profile-view .info-wrapper {
      display: flex;
      flex-direction: row;
      width: 100%; }
      .feeds-container .feeds-item .profile-view .info-wrapper .info-edit {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center; }
        .feeds-container .feeds-item .profile-view .info-wrapper .info-edit .edit-feed-button {
          margin-right: 0px !important;
          margin-left: 10px !important;
          background-color: #37c977 !important;
          color: white !important;
          font-family: "CircularStd" !important;
          font-weight: 100 !important;
          font-size: .875rem;
          padding-left: 1rem !important;
          padding-right: 1rem !important; }
        .feeds-container .feeds-item .profile-view .info-wrapper .info-edit .edit-feed-button:hover {
          opacity: .8; }
    .feeds-container .feeds-item .profile-view .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start; }
      .feeds-container .feeds-item .profile-view .info .name {
        font-size: 1rem;
        font-weight: 100;
        color: black;
        cursor: pointer; }
      .feeds-container .feeds-item .profile-view .info .practice {
        font-size: 1rem;
        font-weight: 100;
        color: #37c977; }
      .feeds-container .feeds-item .profile-view .info .time {
        font-size: 1rem;
        font-weight: 100;
        color: #888888; }
      .feeds-container .feeds-item .profile-view .info .sub-info {
        flex-flow: wrap; }
        .feeds-container .feeds-item .profile-view .info .sub-info label.helped {
          padding-left: 0px;
          padding-right: 7px;
          font-weight: 100;
          margin-right: 0;
          padding-top: 2px; }
        .feeds-container .feeds-item .profile-view .info .sub-info label.caption {
          white-space: nowrap;
          font-weight: 100; }
        .feeds-container .feeds-item .profile-view .info .sub-info label {
          font-weight: 100 !important; }
  .feeds-container .feeds-item .pad {
    padding: 0 12px 2px 0px; }
  .feeds-container .feeds-item .pad.first {
    padding-left: 0px !important; }
  .feeds-container .feeds-item .extra-info {
    width: 100%;
    margin: 8px 0 0;
    padding: 16px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .feeds-container .feeds-item .button-info {
    padding: 16px 0 0; }
  .feeds-container .feeds-item Button {
    font-size: 11pt;
    text-transform: none;
    border-radius: 5px;
    margin-right: 15px;
    min-height: 36px;
    height: 36px;
    min-width: unset; }
  .feeds-container .feeds-item .user-know-button {
    background-color: rgba(27, 102, 233, 0.1);
    color: #1b66e9;
    font-weight: 600; }
    .feeds-container .feeds-item .user-know-button:disabled {
      background-color: #1b66e9;
      color: white;
      font-weight: 400; }
  .feeds-container .feeds-item .agree-button {
    background-color: rgba(55, 201, 119, 0.1);
    color: #37c977;
    font-weight: 600; }
    .feeds-container .feeds-item .agree-button:disabled {
      background-color: #37c977;
      color: white;
      font-weight: 400; }
  .feeds-container .feeds-item .read-more {
    padding-left: 20px !important;
    padding-right: 20px !important;
    height: 36px !important; }
  .feeds-container .feeds-item .sub-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    min-width: 0;
    min-height: 0;
    text-transform: none;
    flex-flow: wrap; }
    .feeds-container .feeds-item .sub-info .helped, .feeds-container .feeds-item .sub-info .helped.total {
      font-size: 1rem;
      font-weight: 600;
      color: #1b66e9;
      margin: 0 4px 0 0; }
    .feeds-container .feeds-item .sub-info .helped.total {
      padding-left: 0px !important;
      padding-right: 4px;
      margin-right: 0;
      padding-top: 2px; }
    .feeds-container .feeds-item .sub-info .like-btn {
      color: red;
      background-color: rgba(255, 23, 68, 0.2);
      width: 36px;
      height: 36px;
      font-size: 14pt; }
    .feeds-container .feeds-item .sub-info .agreed-count {
      font-size: 1rem;
      font-weight: 600;
      color: #37c977;
      margin: 0 4px 0 0; }
    .feeds-container .feeds-item .sub-info .caption {
      font-size: 1rem;
      font-weight: 100;
      color: black;
      margin: 0 5px 0 0;
      flex: 0;
      white-space: nowrap; }
    .feeds-container .feeds-item .sub-info img {
      width: 12px;
      margin-right: 4px; }
    .feeds-container .feeds-item .sub-info label {
      font-weight: 100 !important; }
  .feeds-container .feeds-item .description {
    font-size: 1.2rem;
    font-weight: 100;
    padding: 8px 0;
    line-height: 20pt; }
  .feeds-container .feeds-item .post-image-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 6px;
    width: 100%;
    max-height: 400px; }
  .feeds-container .feeds-item .post-image {
    width: 100%; }

.feeds-container .pt-0 {
  padding-top: 0x !important; }

@media only screen and (max-width: 768px) {
  .feeds-container .feeds-item .title {
    font-size: 1.6875rem; }
  .feeds-container .feeds-item .profile-view .info {
    flex-direction: column;
    align-items: flex-start; }
    .feeds-container .feeds-item .profile-view .info .name {
      font-size: 1rem; }
    .feeds-container .feeds-item .profile-view .info .practice {
      font-size: 1rem; }
  .feeds-container .feeds-item .description {
    font-size: 1rem;
    line-height: 16pt; } }

.image-detail-container {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center; }
  .image-detail-container .tile-img {
    max-width: 100%;
    max-height: 100%; }

.modal-center {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%) !important; }

.note-detail-top-container .horz-container {
  justify-content: space-between; }

.note-detail-container .note-caption {
  font-size: 12pt;
  font-weight: 400;
  color: #37c977; }

.note-detail-container .note-content {
  font-size: 12pt;
  font-weight: 100;
  color: black;
  width: 100%;
  height: 40vh; }

.note-detail-container .note-img {
  margin: 8px 0;
  align-self: center;
  max-width: 100%;
  max-height: 30vh; }

.note-detail-container .item-container {
  padding: 8px 0 !important;
  align-self: center; }

.note-detail-container .picker-button {
  padding: 0;
  max-width: none !important;
  width: 100%;
  text-transform: none; }

.note-detail-container .ico-content {
  font-size: 12pt; }

.note-list-top-container .horz-container {
  justify-content: space-between; }

.note-list-container .item {
  display: flex;
  justify-content: center; }
  .note-list-container .item .datetime {
    font-family: 'CircularStd';
    font-size: 1.25rem;
    font-weight: 400; }

.note-list-container .MuiListItem-root {
  margin-left: 0px !important;
  border-bottom: 1px solid #D8D8D8; }

.notifications-container .title-view {
  margin-right: 0px !important;
  justify-content: space-between !important; }

.notifications-container .nav-filter-button {
  margin-right: 0px !important; }

.notifications-container .title {
  color: black;
  font-size: 1.6875rem !important;
  font-family: CircularStd;
  font-weight: 400 !important;
  line-height: 30px;
  justify-self: center;
  border-radius: 3px;
  text-transform: none;
  display: flex;
  flex-direction: row;
  align-items: flex-start; }

.notifications-container .notification-item {
  border-bottom: 1px solid #ebeae5;
  margin-left: 0px !important;
  padding-left: 0px !important;
  height: fit-content !important;
  margin-top: 0px !important; }
  .notifications-container .notification-item .name {
    padding: 6px 0px;
    display: block;
    width: 89%;
    font-size: 1rem;
    height: fit-content; }
  .notifications-container .notification-item .when {
    margin-top: 6px;
    font-size: .85rem;
    color: #c8c7cc; }

.button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center; }

.patient-card-list-main-cont .nav-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; }

.patient-card-list-main-cont .patient-card-list-container .item-container {
  width: 100%; }
  .patient-card-list-main-cont .patient-card-list-container .item-container .item {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12pt; }
    .patient-card-list-main-cont .patient-card-list-container .item-container .item .card-info.left {
      display: flex;
      flex-direction: column; }
      .patient-card-list-main-cont .patient-card-list-container .item-container .item .card-info.left label {
        font-weight: 400;
        margin-left: .5rem;
        cursor: pointer; }
    .patient-card-list-main-cont .patient-card-list-container .item-container .item .close {
      font-size: 12pt;
      width: 40px;
      height: 40px;
      min-width: unset;
      border-radius: 50%; }

.patient-card-detail-container .sub-container {
  width: 100%; }

.patient-card-detail-container .card-detail-main-cont .title {
  margin-bottom: 2rem !important; }

.patient-card-detail-container .control-container {
  margin: 4px 0;
  width: 100%; }

.patient-card-detail-container .caption {
  left: 8px;
  font-family: 'CircularStd';
  font-weight: 400;
  font-size: 12pt;
  color: #888888; }

.patient-card-detail-container .focused {
  color: black; }

.patient-card-detail-container .value-input {
  width: 100%;
  padding: 5px 8px;
  font-family: 'CircularStd';
  font-size: 12pt;
  border: 2px solid #dbdbdb;
  border-radius: 8px; }
  .patient-card-detail-container .value-input div {
    min-height: unset;
    margin: 0;
    padding: 5px 0; }

.patient-card-detail-container .underline:before {
  background-color: transparent; }

.patient-card-detail-container .underline:after {
  background-color: #1b66e9; }

.patient-card-detail-container .year, .patient-card-detail-container .month {
  width: 100px; }

.patient-card-detail-container .expire, .patient-card-detail-container .date-delimiter {
  margin: 15px 8px 0;
  font-size: 12pt;
  font-weight: 400;
  color: #888888; }

.patient-card-detail-container * {
  box-sizing: border-box; }

.patient-card-detail-container h1 {
  color: #32325d;
  font-weight: 400;
  line-height: 50px;
  font-size: 40px;
  margin: 20px 0;
  padding: 0; }

.patient-card-detail-container .Checkout {
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
  padding: 0 5px; }

.patient-card-detail-container label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em; }

.patient-card-detail-container form {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 3px solid #e6ebf1;
  display: flex;
  flex-direction: column; }

.patient-card-detail-container input, .patient-card-detail-container .StripeElement {
  width: 100%;
  padding: 5px 8px;
  font-family: 'CircularStd';
  font-size: 1.25rem;
  font-weight: 100;
  border: 1px solid #D8D8D8 !important;
  border-radius: 8px !important;
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  outline: 0;
  background: white; }

.patient-card-detail-container input::placeholder {
  color: #aab7c4; }

.patient-card-detail-container input:focus, .patient-card-detail-container .StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease; }

.patient-card-detail-container .StripeElement.IdealBankElement, .patient-card-detail-container .StripeElement.PaymentRequestButton {
  padding: 0; }

.patient-card-detail-container .btn {
  margin-top: 8px !important;
  width: 100% !important;
  font-size: 12pt !important;
  font-weight: 400 !important;
  color: #37c977 !important;
  background-color: rgba(55, 201, 119, 0.1) !important; }
  .patient-card-detail-container .btn:hover {
    color: white !important;
    background-color: #37c977 !important; }

.sex-label {
  font-size: 12pt;
  color: #72ce5f;
  margin: 0; }

.sex-container {
  padding: 0 !important;
  min-height: 40px; }

.sex-text {
  width: 100%;
  min-height: 20px;
  font-size: 12pt;
  font-weight: 100;
  color: black;
  margin: 0;
  background-color: transparent; }

.item-height {
  min-height: 40px; }

.update-button, .patient-profile-container .MuiCardActions-spacing button {
  margin: 24px;
  width: calc(100% - 48px);
  font-size: 16pt !important;
  font-weight: 400;
  text-transform: none !important;
  outline: unset !important;
  border-radius: 8px !important;
  color: #37c977 !important;
  background-color: transparent !important; }
  .update-button:active, .patient-profile-container .MuiCardActions-spacing button:active {
    outline: unset !important; }
  .update-button:hover, .patient-profile-container .MuiCardActions-spacing button:hover {
    color: white !important;
    background-color: #37c977 !important; }

.normal-button {
  font-size: 11pt !important;
  font-weight: 400;
  text-transform: none !important;
  outline: unset !important;
  border-radius: 8px !important; }
  .normal-button:active {
    outline: unset !important; }

.picker-dialog-paper {
  width: 80%;
  height: 400px; }

.main-patient-profile .title-wrapper {
  width: 100% !important; }
  .main-patient-profile .title-wrapper .title {
    flex: 1 !important; }

.main-patient-profile .patient-profile-title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 0px !important;
  margin-left: 0px !important;
  padding-right: 16px !important; }
  .main-patient-profile .patient-profile-title-wrapper .nav-filter-button {
    margin-right: 0px !important; }

.user-container {
  width: 80%;
  padding: 8px 20%;
  max-height: 100vh;
  overflow: auto;
  position: relative; }
  .user-container .mt-130p {
    margin-top: 130px; }
  .user-container .user-content {
    width: 100%;
    padding: 16px; }
    .user-container .user-content .header {
      width: 100%;
      margin-bottom: 47px;
      display: table; }
      .user-container .user-content .header .back {
        position: absolute;
        top: 28px;
        left: 16px;
        font-size: 1.75rem;
        color: #888888; }
      .user-container .user-content .header label {
        color: black;
        font-size: 2.5rem;
        font-weight: 500; }
    .user-container .user-content .sub-header {
      margin-bottom: 8px; }
      .user-container .user-content .sub-header label {
        font-size: 1.25rem;
        font-weight: 400; }
    .user-container .user-content .patient-profile {
      width: 100%; }
  .user-container .update_button {
    box-sizing: border-box;
    border-radius: 8px;
    margin: 16px;
    width: calc(100% - 32px);
    font-size: 1.5rem;
    font-weight: 400;
    text-transform: none;
    background-color: #37c977;
    color: white !important; }
    .user-container .update_button:hover {
      background-color: rgba(55, 201, 119, 0.5); }
  .user-container .logo-container {
    position: fixed;
    top: 24px;
    left: 24px;
    display: flex;
    flex-direction: row;
    align-items: center; }
    .user-container .logo-container img {
      width: 32px; }
    .user-container .logo-container label {
      margin: 5px 13px 0;
      font-size: 1.8rem;
      font-weight: 500; }

.doctor-signup-container {
  width: 100% !important; }

@media only screen and (max-width: 768px) {
  .user-container {
    padding: 16px 0 0;
    margin-bottom: -60px; }
    .user-container .user-content .header {
      margin-bottom: 24px; }
      .user-container .user-content .header .back {
        font-size: 1.75rem;
        min-width: unset;
        padding: 0;
        top: 32px; }
      .user-container .user-content .header label {
        display: table-cell;
        font-size: 21pt;
        width: 100%;
        text-align: center; }
    .user-container .user-content .sub-header label {
      font-size: 12pt; }
    .user-container .update_button,
    .user-container .push {
      margin: 0;
      width: 100%;
      height: 60px; }
    .user-container .logo-container {
      display: none; } }

.post-article-main-cont .title {
  margin-bottom: 1rem !important; }

.post-article-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 50%; }
  .post-article-container .article-title {
    width: 100%;
    font-size: 1.1rem;
    margin-bottom: 6px;
    font-weight: 100; }
  .post-article-container .url {
    width: 100%;
    font-size: 1rem;
    margin-bottom: 6px;
    font-weight: 100; }
  .post-article-container .body {
    width: 100% !important;
    height: 200px;
    font-size: .9rem;
    font-weight: 100; }
  .post-article-container .save-btn {
    width: 100%;
    background-color: #72ce5f;
    margin-top: 1rem !important; }
  .post-article-container .file-input-wrapper button {
    font-size: 0.85rem !important; }

@media only screen and (max-width: 768px) {
  .post-article-container {
    width: 100%; } }

.post-question-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 12px; }
  .post-question-container .title-wrapper {
    width: 100% !important;
    margin-bottom: 1rem !important;
    margin-left: -84px !important; }
    .post-question-container .title-wrapper .title {
      width: 100% !important; }
  .post-question-container .question-title {
    width: 100%;
    font-size: 16px;
    margin-bottom: 6px; }
  .post-question-container .body {
    width: 100% !important;
    height: 200px;
    margin-bottom: 6px;
    border: 2px solid #ebeae5;
    border-radius: 4px; }
  .post-question-container .update-button, .post-question-container .patient-profile-container .MuiCardActions-spacing button, .patient-profile-container .MuiCardActions-spacing .post-question-container button {
    margin-top: 20px; }

@media only screen and (max-width: 768px) {
  .post-question-container {
    padding: 12px;
    width: 100%; } }

.question-detail-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; }
  .question-detail-container .title {
    margin-bottom: 20px !important; }
  .question-detail-container .question-title {
    margin-top: 1rem;
    font-size: 1.25rem;
    font-weight: 100;
    color: #72ce5f; }
  .question-detail-container .when {
    font-size: 1rem;
    font-weight: 100;
    color: lightgray; }
  .question-detail-container .body {
    padding: 2rem 0;
    font-size: 1rem;
    font-weight: 100;
    color: black; }
  .question-detail-container .reply-btn {
    background-color: #72ce5f;
    text-transform: none;
    color: white;
    font-size: 14px; }

@media only screen and (max-width: 768px) {
  .question-detail-container {
    width: 100%; } }

.questions-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }
  .questions-container .nav-title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
  .questions-container .title-wrapper {
    margin-bottom: 1rem !important; }
  .questions-container .title {
    flex: 1 !important; }
  .questions-container .item {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    border-bottom: 1px solid #ebeae5; }
    .questions-container .item .name {
      font-size: 1.25rem;
      font-weight: 100; }
    .questions-container .item .when {
      font-size: 10px;
      font-weight: 100; }
  .questions-container .MuiListItem-root {
    height: fit-content !important;
    margin: 0px !important; }

@media only screen and (max-width: 768px) {
  .questions-container {
    width: 100%; } }

.title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -44px !important;
  overflow-x: visible; }

.service-container {
  padding: 8px 16px;
  max-width: 368px;
  margin: auto; }
  .service-container .title-wrapper button {
    padding: 0px 10px 0px 10px; }
  .service-container .update-button, .service-container .patient-profile-container .MuiCardActions-spacing button, .patient-profile-container .MuiCardActions-spacing .service-container button {
    font-size: 1rem !important;
    font-weight: 400 !important; }
  .service-container .title {
    margin-bottom: 1rem !important; }
  .service-container .name {
    font-size: 1.5rem;
    font-weight: 100;
    text-align: center;
    margin-top: 8px; }
  .service-container .area-of-practice {
    font-size: 1.2rem;
    font-weight: 100;
    text-align: center;
    color: #888888;
    margin-top: 8px;
    margin-bottom: 16px; }
  .service-container .avatar-container {
    position: relative; }
    .service-container .avatar-container .avatar {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      object-fit: cover; }
    .service-container .avatar-container .fav-btn {
      position: absolute;
      right: 2%;
      top: 2%;
      padding: 6px;
      border-radius: 50%;
      background-color: white;
      min-width: unset;
      min-height: unset;
      font-size: 11pt; }
  .service-container .info1-space {
    margin-right: 20px; }
  .service-container .patient-helped-value {
    color: red;
    font-size: 12pt;
    margin-right: 4px; }
  .service-container .star-container {
    margin: 0 0 12px; }
    .service-container .star-container > span > span {
      margin: 4px; }
  .service-container .service-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #c8c7cc !important;
    margin: 4px 0;
    border-radius: 10px;
    margin-top: 15px !important; }
    .service-container .service-item .service-ico {
      margin-right: 12px !important;
      fill: #37c977; }
    .service-container .service-item label {
      margin-bottom: 0;
      font-weight: 100;
      font-size: 1.2rem;
      color: #888888; }
  .service-container .map-btn {
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;
    border: 2px solid #dbdbdb;
    border-radius: 8px;
    font-family: 'CircularStd';
    font-size: 12pt;
    color: #888888; }
  .service-container .full-width {
    width: 100%; }

.service-list-container {
  width: 100%; }
  .service-list-container .title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-right: 0px !important;
    margin-left: 0px !important; }
  .service-list-container .item-container {
    width: 100%;
    padding: 14px;
    border-bottom: 1px solid #ebeae5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-transform: none; }
    .service-list-container .item-container label {
      cursor: pointer; }
    .service-list-container .item-container .left-view {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 7px;
      margin-bottom: 7px; }
      .service-list-container .item-container .left-view .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px; }
      .service-list-container .item-container .left-view .info-view {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-left: 16px; }
        .service-list-container .item-container .left-view .info-view .name {
          font-family: 'CircularStd';
          font-size: 1.25rem;
          font-weight: 100;
          text-align: left;
          line-height: 1.1em;
          color: black;
          margin-right: 10px; }
        .service-list-container .item-container .left-view .info-view .area-of-practice {
          margin-top: 6px !important;
          font-family: 'CircularStd';
          font-size: 1rem;
          font-weight: 100;
          color: #72ce5f;
          text-align: left;
          margin-top: 4px;
          line-height: 1.1em; }
    .service-list-container .item-container .right-view {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      min-width: 110px; }
      .service-list-container .item-container .right-view .rating-desp {
        font-family: 'CircularStd';
        font-size: 11pt;
        font-weight: 400;
        color: #c8c7cc;
        text-align: end;
        margin-top: 4px; }

@media only screen and (max-width: 768px) {
  .service-list-container {
    padding: unset; } }

.service-schedule-container {
  height: 80vh;
  overflow: hidden;
  display: flex;
  flex-direction: column; }
  .service-schedule-container .service-schedule-profile-title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start; }
    .service-schedule-container .service-schedule-profile-title-wrapper .title {
      margin-bottom: 1.5rem !important;
      flex: 1; }
  .service-schedule-container .sub-container {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden; }
    .service-schedule-container .sub-container .item-wrapper {
      margin: 8px 0;
      /**************** Date picker css ***************/
      /*************************************************/ }
      .service-schedule-container .sub-container .item-wrapper > div {
        flex: 1; }
      .service-schedule-container .sub-container .item-wrapper .react-datepicker-wrapper {
        display: block; }
      .service-schedule-container .sub-container .item-wrapper .react-datepicker__input-container {
        display: block; }
      .service-schedule-container .sub-container .item-wrapper .react-datepicker__navigation {
        top: 16px; }
      .service-schedule-container .sub-container .item-wrapper .react-datepicker-time__header,
      .service-schedule-container .sub-container .item-wrapper .react-datepicker__current-month,
      .service-schedule-container .sub-container .item-wrapper .react-datepicker__year-dropdown-container,
      .service-schedule-container .sub-container .item-wrapper .react-datepicker__day-name,
      .service-schedule-container .sub-container .item-wrapper .react-datepicker__day {
        font-size: 12pt;
        margin: 4px 8px; }
      .service-schedule-container .sub-container .item-wrapper .react-datepicker__time-container {
        width: 100px; }
        .service-schedule-container .sub-container .item-wrapper .react-datepicker__time-container .react-datepicker__time-box {
          width: 100%; }
      .service-schedule-container .sub-container .item-wrapper .react-datepicker__time-list {
        padding: 0; }
        .service-schedule-container .sub-container .item-wrapper .react-datepicker__time-list .react-datepicker__time-list-item {
          font-size: 12pt; }
      .service-schedule-container .sub-container .item-wrapper .datepicker-item {
        flex: 1;
        max-width: unset; }
      .service-schedule-container .sub-container .item-wrapper .booking-number, .service-schedule-container .sub-container .item-wrapper .house-call-price {
        flex: 1; }
    .service-schedule-container .sub-container .caption {
      font-size: 1.2rem;
      font-weight: 400;
      min-width: 130px; }
    .service-schedule-container .sub-container table {
      margin: 24px 0;
      width: 100%; }
    .service-schedule-container .sub-container th {
      font-size: 12pt;
      font-weight: 500; }
    .service-schedule-container .sub-container td {
      font-size: 12pt;
      font-weight: 400; }
    .service-schedule-container .sub-container tr {
      border-bottom: 1px solid #dbdbdb; }
  .service-schedule-container .repeat-button-wrapper {
    padding-bottom: 10px;
    padding-top: 10px; }
  .service-schedule-container .schedule-table-wrapper {
    flex: 1;
    overflow: auto; }
    .service-schedule-container .schedule-table-wrapper .schedule-table tr {
      height: 2rem; }

.settings-container {
  width: 100%;
  height: 80vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin: auto;
  font-size: 14px; }
  .settings-container .title {
    margin-bottom: 1rem !important; }
  .settings-container .title-wrapper {
    margin-bottom: 1rem !important; }
    .settings-container .title-wrapper .title {
      margin-bottom: 0px !important; }
  .settings-container .item {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1.25rem; }
    .settings-container .item label {
      font-weight: 100; }
  .settings-container img.details-arrow {
    height: 12px; }
  .settings-container .bottom {
    border-bottom: 1px solid #ebeae5; }
  .settings-container .sub-title {
    font-weight: bold !important; }
  .settings-container .save-wrapper {
    background-color: white;
    padding-top: 12px;
    padding-bottom: 12px; }
  .settings-container .save {
    background-color: rgba(114, 206, 95, 0.1);
    color: #37c977;
    font-size: 1rem;
    text-transform: none;
    height: 50px;
    width: 100%; }
  .settings-container .save:hover {
    background-color: #37c977;
    color: white; }
  .settings-container .MuiListItem-root {
    height: fit-content !important;
    margin: 0px !important;
    padding-top: 28px !important;
    padding-bottom: 28px !important; }
  .settings-container .item.bottom.switch span {
    margin-right: 0px !important; }

@media only screen and (max-width: 768px) {
  .settings-container {
    width: 100%;
    height: 70vh; }
    .settings-container .sub-title, .settings-container .save {
      background-color: #72ce5f;
      color: white; }
    .settings-container .MuiListItem-root {
      padding-top: 12px !important;
      padding-bottom: 12px !important; } }

.demo-main-container .title-wrapper {
  margin-bottom: 2rem !important; }

.demo-main-container .Demo__some-network {
  vertical-align: top;
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 30px;
  text-align: center; }

.demo-main-container .Demo__some-network__share-count {
  margin-top: 3px;
  font-size: 12px; }

.demo-main-container .Demo__some-network__share-button {
  cursor: pointer; }

.demo-main-container .Demo__some-network__share-button:hover:not(:active) {
  opacity: 0.75; }

.splash-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 550px; }
  .splash-container .logo-view {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../img/authentication/back.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    overflow: hidden;
    z-index: -1; }
    .splash-container .logo-view::after {
      content: "";
      background-color: black;
      opacity: 0.55;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      position: absolute;
      z-index: 0; }
    .splash-container .logo-view .logo {
      z-index: 1; }
      .splash-container .logo-view .logo img {
        width: 80px; }
    .splash-container .logo-view .logo-title {
      margin-top: 10px;
      z-index: 1; }
      .splash-container .logo-view .logo-title label {
        font-size: 2rem; }
        .splash-container .logo-view .logo-title label.e {
          color: white; }
        .splash-container .logo-view .logo-title label.docine {
          color: white; }
    .splash-container .logo-view .sub-title {
      margin-top: 20px;
      z-index: 1;
      font-size: 1rem;
      font-weight: 400;
      text-align: center;
      padding: 0 8px;
      color: rgba(255, 255, 255, 0.8); }
      .splash-container .logo-view .sub-title label {
        font-weight: 400 !important; }
  .splash-container .buttons-view {
    padding: 0 24px;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center; }
    .splash-container .buttons-view a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .splash-container .buttons-view .button-item {
      border-bottom: 1px solid rgba(0, 0, 0, 0); }
      .splash-container .buttons-view .button-item img {
        width: 80px; }
      .splash-container .buttons-view .button-item .bottom {
        margin-top: 20px; }
        .splash-container .buttons-view .button-item .bottom label {
          font-size: 1.25rem; }
          .splash-container .buttons-view .button-item .bottom label.iam {
            color: black;
            font-weight: 500 !important; }
          .splash-container .buttons-view .button-item .bottom label.doctor-caption {
            color: #37c977 !important;
            font-weight: 500 !important;
            background: transparent; }
          .splash-container .buttons-view .button-item .bottom label.patient-caption {
            color: #1b66e9 !important;
            font-weight: 500 !important;
            background-color: transparent !important; }
    .splash-container .buttons-view .button-item:hover {
      border-bottom: 1px solid black; }

@media only screen and (max-width: 768px) {
  .splash-container {
    flex-direction: column; }
    .splash-container .logo-view {
      width: 100%;
      height: 50%;
      justify-content: flex-end;
      background-image: none; }
      .splash-container .logo-view::after {
        content: "";
        display: none; }
      .splash-container .logo-view .logo img {
        width: 80px; }
      .splash-container .logo-view .logo-title {
        margin-top: 15px; }
        .splash-container .logo-view .logo-title label.e {
          color: #72ce5f; }
        .splash-container .logo-view .logo-title label.docine {
          color: black; }
      .splash-container .logo-view .sub-title {
        margin-top: 20px;
        font-size: 10pt;
        color: rgba(136, 136, 136, 0.7); }
    .splash-container .buttons-view {
      width: 100%;
      height: 50%;
      align-items: flex-start;
      padding-top: 60px; }
      .splash-container .buttons-view .button-item img {
        width: 40px; }
      .splash-container .buttons-view .button-item .bottom label {
        font-size: 10pt; } }

.eservice-container {
  padding-bottom: 2rem; }
  .eservice-container .title {
    margin-bottom: 1rem !important; }
  .eservice-container .item-input-desc.first {
    margin-top: 0px; }
  .eservice-container .item-input-desc {
    padding-left: 4px;
    padding-right: 4px;
    margin-top: 20px;
    font-size: 1rem;
    font-weight: 100;
    color: rgba(0, 0, 0, 0.4); }
  .eservice-container .error {
    border: 1px solid #EE3000 !important; }
  .eservice-container .ico-content {
    padding-right: 10px !important; }
  .eservice-container .react-datepicker-wrapper {
    width: 100%; }
    .eservice-container .react-datepicker-wrapper div {
      width: 100%; }
  .eservice-container .text-area {
    min-height: 200px; }
  .eservice-container .save-button {
    box-sizing: border-box;
    border-radius: 8px;
    margin: 16px;
    width: calc(100% - 32px);
    font-size: 1rem;
    font-weight: 400;
    text-transform: none;
    background-color: transparent;
    color: #37c977 !important; }
    .eservice-container .save-button:hover {
      background-color: #37c977;
      color: white !important; }
  .eservice-container .dropdown-container .item {
    height: fit-content !important; }
  .eservice-container .dropdown-container .item-input {
    height: fit-content !important;
    min-height: 56px !important; }
  .eservice-container .signature-thumb-container {
    margin-top: 2rem;
    border: 1px solid black;
    height: fit-content; }

@media only screen and (max-width: 768px) {
  .eservice-container .save-button {
    margin: 0;
    width: 100%;
    height: 60px; } }

:root {
  font-size: 16px !important; }

body {
  font-family: 'CircularStd' !important; }

.ml-8 {
  margin-left: 8px !important; }

.mr-8 {
  margin-right: 8px !important; }

.mb-8 {
  margin-bottom: 8px !important; }

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center; }

.relative-container {
  position: relative; }

::-webkit-scrollbar {
  display: none; }

.bottom-pad {
  padding-bottom: 2px; }

.no-padding-horz {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.no-border {
  border: 0; }

.bottom-border {
  border: 0;
  border-bottom: 1px solid #ebeae5; }

label {
  margin: 0;
  vertical-align: middle; }

.file-input-wrapper {
  color: white;
  font-family: "Gotham-Bold";
  cursor: pointer;
  position: relative;
  text-align: center;
  width: 100%; }
  .file-input-wrapper > input[type="file"] {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    height: 100%;
    width: 100%; }

.sidebar-item {
  display: flex !important;
  align-items: center !important; }
  .sidebar-item.sel, .sidebar-item:hover {
    background-color: rgba(55, 201, 119, 0.28) !important; }
    .sidebar-item.sel div, .sidebar-item:hover div {
      color: #37c977 !important; }

.star {
  color: #d89b2c; }

.star-place-holder {
  color: yellow; }

.switchBase {
  color: #72ce5f; }
  .switchBase .checked {
    color: #72ce5f; }
    .switchBase .checked + .bar {
      background-color: #72ce5f; }

.switch-base {
  transform: translateX(12px); }
  .switch-base .switch-bar {
    background-color: red;
    opacity: 1;
    border: none;
    width: 42px;
    height: 26px;
    border-radius: 99999px;
    margin-top: -13px;
    margin-left: -21px; }
  .switch-base .switch-icon {
    width: 24px;
    height: 24px; }
  .switch-base .switch-icon-check {
    box-shadow: 1px; }
  .switch-base .switch-checked {
    color: white; }
    .switch-base .switch-checked + .switch-bar {
      background-color: #72ce5f;
      opacity: 1; }

.dropdown-container {
  background-color: transparent; }
  .dropdown-container .item {
    padding: 0 !important;
    margin: 0px !important; }

.menu-item {
  font-family: 'CircularStd' !important;
  font-size: 1.25rem !important; }

button {
  text-transform: none !important;
  font-weight: 400 !important; }

.defaultButton {
  background-color: #72ce5f !important;
  text-transform: none !important;
  font-size: 1.2em !important;
  margin: 6px !important;
  color: white !important; }

.doctor-btn {
  width: 100% !important;
  background-color: rgba(55, 201, 119, 0.1) !important;
  color: #37c977 !important;
  font-size: 12pt !important; }
  .doctor-btn:hover {
    background-color: #37c977 !important;
    color: white !important; }

.patient-btn {
  width: 100%;
  margin-top: 2rem !important; }

.nav-filter-button {
  text-transform: none !important;
  font-size: 1.2em !important;
  min-width: 0 !important;
  margin-right: 10px !important;
  margin-left: 20px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  background-color: #37c977 !important;
  color: white !important;
  border-radius: 10px !important; }

.nav-container {
  color: rgba(0, 0, 0, 0.5) !important; }

.nav-btn {
  min-width: unset !important;
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 16pt !important; }

.search-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }
  .search-container input {
    outline-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: 20px;
    padding: 4px 8px; }
    .search-container input:active {
      outline-color: #37c977; }

.st0 {
  opacity: 0.1;
  fill: #37C977; }

.st1 {
  fill: #37C977; }

.st2 {
  fill: #FFFFFF; }

.st3 {
  opacity: 0.68;
  fill: #37C977; }

.MuiListItemIcon-root {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  margin: 0px !important; }
  .MuiListItemIcon-root svg {
    width: 20px !important; }

.MuiListItem-root {
  margin: 8px 0px 0px 10px !important;
  height: 56px !important; }
  .MuiListItem-root .MuiListItemText-root, .MuiListItem-root .e-menu-logo-wrapper {
    font-size: 1.5rem !important;
    margin-right: 15px !important;
    font-weight: lighter !important; }
  .MuiListItem-root svg {
    width: 30px !important;
    height: auto !important;
    margin: 0px !important; }

.sidebar-wrapper .MuiListItem-root {
  height: fit-content !important; }
  .sidebar-wrapper .MuiListItem-root .MuiListItemText-root, .sidebar-wrapper .MuiListItem-root .e-menu-logo-wrapper {
    font-size: 1.2rem !important;
    font-weight: lighter !important; }
  .sidebar-wrapper .MuiListItem-root svg {
    width: 22px !important;
    height: auto !important;
    margin: 0px !important; }

.favorites-container .MuiListItem-root, .service-list-container .MuiListItem-root {
  height: fit-content !important;
  margin: 0px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important; }

.patient-profile .MuiListItem-root {
  margin: 0px !important;
  height: 56px !important; }

.MuiListItemText-root, .e-menu-logo-wrapper {
  font-family: "CircularStd" !important;
  font-size: 1.25rem !important;
  font-weight: 500 !important; }

.sidebar-item.sel, .sidebar-item:hover {
  background-color: rgba(55, 201, 119, 0.15) !important; }

.MuiAppBar-root {
  margin-top: 10px !important;
  margin-bottom: 20px !important; }

.MuiToolbar-root .MuiListItem-root {
  height: fit-content !important;
  padding: 0px !important;
  margin: 0px 20px 0px 0px !important; }
  .MuiToolbar-root .MuiListItem-root .MuiListItemText-root, .MuiToolbar-root .MuiListItem-root .e-menu-logo-wrapper {
    font-size: 1.2rem !important;
    margin-right: 15px !important;
    font-weight: lighter !important; }
  .MuiToolbar-root .MuiListItem-root img {
    width: 60px !important;
    height: auto !important;
    margin: 0px !important; }

.MuiButton-label {
  font-family: 'CircularStd';
  font-weight: 400; }

.MuiDialog-paperWidthXs {
  min-width: 400px !important; }

.MuiDrawer-paper {
  box-shadow: none !important; }

.MuiCardHeader-avatar {
  box-shadow: none !important; }

.MuiCard-root label {
  font-weight: 100 !important;
  color: black; }

.mainPanel {
  width: 60% !important;
  margin-left: 0px !important;
  padding-left: 100px !important; }

.mainPanel > .MuiGrid-container {
  width: 60% !important; }

.sidebar-wrapper {
  width: 100%;
  height: calc(100vh - 75px);
  display: flex;
  flex-direction: column; }

.e-menu-wrapper {
  width: 100%; }

.e-menu-logo-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 25px !important;
  color: #37c977 !important; }

.file-input-wrapper img {
  border-radius: 50%; }

.photo-size {
  color: #666;
  font-size: .75rem;
  font-weight: 100;
  white-space: nowrap;
  margin-left: 1rem; }

.file-input-wrapper-main-cont {
  position: relative;
  background-clip: none !important; }
  .file-input-wrapper-main-cont .patient-favorite {
    position: absolute;
    right: 2%;
    top: 2%;
    padding: 6px;
    border-radius: 50%;
    background-color: white;
    min-width: unset;
    min-height: unset;
    font-size: 11pt; }

.no-results-container {
  padding: 3rem 1rem;
  color: #646464;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

.title-wrapper .MuiSvgIcon-root:hover {
  color: rgba(0, 0, 0, 0.8); }

.title-wrapper.padding {
  padding: .5rem 0; }

@media only screen and (max-width: 768px) {
  .mainPanel {
    width: 100% !important;
    margin-left: 0px !important;
    padding-left: 0px !important; }
    .mainPanel .MuiGrid-container {
      width: 100% !important; }
  :root {
    font-size: 12px !important; } }

.search-container {
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px; }

.header-container .MuiIconButton-label .MuiSvgIcon-root {
  width: 20px;
  height: 20px; }
